import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const TechnologyTransferAssociationPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "tta-page" */'./tta.page.js'),
	modules: ['./tta.page.js'],
	webpack: () => [require.resolveWeak('./tta.page.js')],
	loading: CustomLoadableLoading,
});

export default TechnologyTransferAssociationPageAsync;
