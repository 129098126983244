import React from 'react';
import './buttons.scss';
import { Link } from 'react-router-dom';

const PrimaryButton = ({
	big, disabled, children, type, extraClasses, onclick, link, external,
}) => {
	if (onclick) {
		return (
			<button
				disabled={disabled}
				onClick={onclick}
				type={type}
				className={`btn btn-primary ${big ? 'btn-lg' : ''} ${extraClasses}`}
			>
				{children}
			</button>
		);
	}

	if (link && external) {
		return (
			<a
				className={`btn btn-primary ${big ? 'btn-lg' : ''} ${extraClasses}`}
				href={link}
				target="_blank"
				rel="noopener noreferrer"
			>
				{children}
			</a>
		);
	}

	if (link) {
		return (
			<Link
				disabled={disabled}
				className={`btn btn-primary ${big ? 'btn-lg' : ''} ${extraClasses}`}
				to={link}
			>
				{children}
			</Link>
		);
	}
	return (
		<button
			disabled={disabled}
			className={`btn btn-primary ${big ? 'btn-lg' : ''} ${extraClasses}`}
			type={type}
		>
			{children}
		</button>
	);
};

const SecondaryButton = ({ disabled, children }) => (
	<button disabled={disabled} className="btn btn-outline-primary">
		{children}
	</button>
);

export { PrimaryButton, SecondaryButton };
