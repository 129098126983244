import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../../../components/loading/loading.js';

const EventDetailPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "event-detail-page" */'./event-detail.page.js'),
	modules: ['./event-detail.page.js'],
	webpack: () => [require.resolveWeak('./event-detail.page.js')],
	loading: CustomLoadableLoading,
});

export default EventDetailPageAsync;