import axios from 'axios';

function logError(error) {
	console.log(error);
	throw error;
}

class SearchService {
	getSearchData(query, language) {
		return axios
			.get(`${process.env.REACT_APP_API_ENDPOINT}/api/detektivo/all`, {
				params: {
					token: process.env.REACT_APP_API_DETEKTIVO_TOKEN,
					q: query,
					l: language
				}
			})
			.then(res => res.data)
			.catch(logError);
	}
}

export default new SearchService();
