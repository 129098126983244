function validateEmail(email) {
	const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

	return emailRegex.test(email);
}

function validateUrl(url) {
	const urlRegex = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;

	return urlRegex.test(url);
}

function getFileExtension(filePath) {
	const lastDot = filePath.lastIndexOf('.');
	const extension = filePath.substring(lastDot + 1);
	return extension;
}

function getFileName(filePath) {
	const lastSlash = filePath.lastIndexOf('\\');
	const name = filePath.substring(lastSlash + 1, filePath.length);
	return name;
}

function scrollToTop() {
	window.scrollTo({
		top: 0,
	});
}

function shuffleArr(array) {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * i);
		const temp = array[i];
		array[i] = array[j];
		array[j] = temp;
	}
	return array;
}

function escapeRegExp(text) {
  return text.replace(/[-[\]{}()*+?.,"~/\\^$|#\s]/g, '\\$&');
}

export { validateEmail, validateUrl, getFileExtension, getFileName, scrollToTop, shuffleArr, escapeRegExp };
