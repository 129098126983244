import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../../components/loading/loading.js';

const EventsPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "news-page" */'./events.page.js'),
	modules: ['./events.page.js'],
	webpack: () => [require.resolveWeak('./events.page.js')],
	loading: CustomLoadableLoading,
});

export default EventsPageAsync;
