import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const ContactsPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "contacts-page" */'./contacts.page.js'),
	modules: ['./contacts.page.js'],
	webpack: () => [require.resolveWeak('./contacts.page.js')],
	loading: CustomLoadableLoading,
});

export default ContactsPageAsync;
