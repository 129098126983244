import React from 'react';
import { connect } from 'redux-zero/react';

import { actions } from '../../shared/store.js';

import Loader from '../../assets/loading.svg';

import './loading.scss';

const Loading = () => (
	<div className="loading">
		<img src={Loader} alt="Loading" />
	</div>
);

const mapToProps = ({ seeker, isLoading }) => ({
	seeker,
	isLoading,
});

const CustomLoadableLoading = connect(
	mapToProps,
	actions,
)(
	class CustomLoadableLoadingInternal extends React.Component {
		constructor(props) {
			super(props);

			props.setLoading(true);
		}

		render() {
			return null;
		}
	},
);

export default Loading;

export { CustomLoadableLoading };
