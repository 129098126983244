import localizeRoutes from './localize-routes.js';

import HomePageAsync from '../features/home/home.page.async.js';
import CompaniesPageAsync from '../features/companies/companies.page.async.js';
import ParkPageAsync from '../features/park/park.page.async.js';
import ServicesPageAsync from '../features/services/services.page.async.js';
import EcoSystemPageAsync from '../features/ecosystem/ecosystem.page.async.js';
import ContactsPageAsync from '../features/contacts/contacts.page.async.js';
import CareersPageAsync from '../features/careers/careers.page.async.js';
import NewsPageAsync from '../features/news-events/news/news.page.async.js';
import EventsPageAsync from '../features/news-events/events/events.page.async.js';
import NewsDetailsPageAsync from '../features/news-events/news/news-detail/news-detail.page.async.js';
import EventDetailsPageAsync from '../features/news-events/events/event-detail/event-detail.page.async.js';
import TechnologyTransferAssociationPageAsync from '../features/tta/tta.page.async.js';

const routes = [
	{
		path: '/',
		exact: true,
		component: HomePageAsync,
	},
	{
		path: '/park',
		component: ParkPageAsync,
	},
	{
		path: '/services',
		component: ServicesPageAsync,
	},
	{
		path: '/companies',
		component: CompaniesPageAsync,
	},
	{
		path: '/ecosystem',
		component: EcoSystemPageAsync,
	},
	{
		path: '/news',
		exact: true,
		component: NewsPageAsync,
	},
	{
		path: '/events',
		exact: true,
		component: EventsPageAsync,
	},
	{
		path: '/news/:slug',
		exact: true,
		component: NewsDetailsPageAsync,
	},
	{
		path: '/events/:slug',
		exact: true,
		component: EventDetailsPageAsync,
	},
	{
		path: '/contacts',
		component: ContactsPageAsync,
	},
	{
		path: '/careers',
		component: CareersPageAsync,
	},
	{
		path: '/technology-transfer-association',
		component: TechnologyTransferAssociationPageAsync,
	},
];

export default localizeRoutes(routes);
