import axios from 'axios';

function logError(error) {
	console.log(error);
	throw error;
}

class MainService {
	getContactsData(language) {
		return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/singletons/get/Contacts?lang=${language}`,
			{ populate: 1 })
			.then(res => res.data)
			.catch(logError);
	}
}

export default new MainService();
