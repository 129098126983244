import createStore from 'redux-zero';

const initialStore = {
	isLoading: true,
	homePageData: null,
	homeNewsEventsData: null,
	parkPageData: null,
	servicesPageData: null,
	companiesPageData: null,
	companies: null,
	ecosystemPageData: null,
	contactsPageData: null,
	ecosystemGalleries: null,
	news: null,
	totalNews: null,
	newsDetail: null,
	upcomingEvents: [],
	pastEvents: [],
	totalUEvents: null,
	totalPEvents: null,
	careersPageData: null,
};

function initialState({ ...props }) {
	return Object.assign(
		{},
		{
			...initialStore,
			...props,
		},
	);
}

const actions = () => ({
	setLoading: (state, value) => ({
		isLoading: value,
	}),
	setHomePageData: (state, value) => ({
		homePageData: value,
	}),
	setHomeNewsEventsData: (_, value) => ({
		homeNewsEventsData: value,
	}),
	setParkPageData: (state, value) => ({
		parkPageData: value,
	}),
	setServicesPageData: (state, value) => ({
		servicesPageData: value,
	}),
	setCompaniesPageData: (state, value) => ({
		companiesPageData: value,
	}),
	setCompanies: (state, value) => ({
		companies: value,
	}),
	setEcosystemPageData: (state, value) => ({
		ecosystemPageData: value,
	}),
	setEcosystemGalleries: (state, galleryId, value) => {
		const currentState = { ...state };
		currentState.ecosystemGalleries = state.ecosystemGalleries || [];
		currentState.ecosystemGalleries[galleryId] = value;

		return currentState;
	},
	setNews: (state, value) => ({
		news: value,
	}),
	setTotalNews: (state, value) => ({
		totalNews: value,
	}),
	setNewsDetail: (state, value) => ({
		newsDetail: value,
	}),
	setUpcomingEvents: (state, value) => ({
		upcomingEvents: value,
	}),
	setPastEvents: (state, value) => ({
		pastEvents: value,
	}),
	setTotalUEvents: (state, value) => ({
		totalUEvents: value,
	}),
	setTotalPEvents: (state, value) => ({
		totalPEvents: value,
	}),
	setEventDetail: (state, value) => ({
		eventDetail: value,
	}),
	setContactsData: (state, value) => ({
		contactsPageData: value,
	}),
	setCareersData: (state, value) => ({
		careersPageData: value,
	}),
	setTtaPageData: (_, value) => ({
		ttaPageData: value,
	}),
	resetStore: () => (initialStore),
});

let store = null;

function initializeStoreClient(state) {
	store = createStore(initialState(state));
	return store;
}

function initializeStoreServer(state) {
	store = createStore(initialState(state));
	return store;
}

export {
	initializeStoreClient, initializeStoreServer, store, actions,
};
