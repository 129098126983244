import React, { Component } from 'react';
import searchIcon from '../../assets/icons/search/ico_search.svg';
import closeIcon from '../../assets/icons/search/ico_x.svg';
import enterIcon from '../../assets/icons/search/ico_enter.svg';
import { FormattedMessage } from 'react-intl';

class SearchInput extends Component {
	render() {
		return (
			<div className="search-input container">
				<form onSubmit={this.props.onSubmit}>
					<div className="icon-container">
						<img src={searchIcon} className="search-icon" alt="Search" />
					</div>

					<FormattedMessage id="search.placeholder">
						{placeholder => (
							<input
								ref={this.props.inputRef}
								type="search"
								value={this.props.value}
								onChange={this.props.onChange}
								placeholder={placeholder}
							/>
						)}
					</FormattedMessage>

					{this.props.onClose ? (
						<button
							type="button"
							onClick={this.props.onClose}
							className="close-button"
						>
							<img src={closeIcon} alt="Close" />
						</button>
					) : (
						<span className="enter-placeholder">
							<FormattedMessage
								id="search.enter"
								values={{ enter: <img src={enterIcon} alt="enter" /> }}
							/>
						</span>
					)}
				</form>
			</div>
		);
	}
}

export default SearchInput;
