import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Switch, withRouter } from 'react-router';
// eslint-disable-next-line import/extensions
import { connect } from 'redux-zero/react';
import { Helmet } from 'react-helmet';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en.js';
import pt from 'react-intl/locale-data/pt.js';

import { actions } from './shared/store.js';
import I18NLanguages from './i18n/index.js';
import routes from './routes/routes.js';
import { scrollToTop } from './shared/utils.js';
import Navbar from './layout/navbar.js';
import Footer from './layout/footer.js';
import Loading from './components/loading/loading.js';
import NotFound from './components/not-found/page-not-found.js';
import Search from './components/search/search';

import LocaleProvider, {
	LocaleContext,
	RedirectIfWrongUrlLocale,
} from './locales/locale-context.js';

const intlMessages = I18NLanguages.reduce((result, language) => {
	// eslint-disable-next-line no-param-reassign
	result[language.code] = language.value;
	return result;
}, {});

addLocaleData([...en, ...pt]);

class App extends Component {
	state = {
		navbarOpen: false,
	};

	componentDidUpdate(prevProps) {
		const { location } = this.props;

		if (location !== prevProps.location) {
			scrollToTop();
		}
	}

	toggleNavbar = () => {
		this.setState(prevState => ({
			navbarOpen: !prevState.navbarOpen,
		}));
	};

	closeNavbar = () => {
		this.setState({
			navbarOpen: false,
		});
	};

	resetStore = () => {
		const { resetStore } = this.props;
		resetStore();
	};

	render() {
		const { isLoading } = this.props;
		const { navbarOpen } = this.state;

		return (
			<div className="app-shell">
				<LocaleProvider>
					<LocaleContext.Consumer>
						{({ locale }) => (
							<IntlProvider
								locale={locale.value}
								messages={intlMessages[locale.value]}
							>
								<React.Fragment>
									<Helmet defaultTitle="Biocant" titleTemplate="Biocant - %s" />

									<RedirectIfWrongUrlLocale />

									<Navbar
										navbarOpen={navbarOpen}
										toggleNav={this.toggleNavbar}
										closeNavbar={this.closeNavbar}
										resetStore={this.resetStore}
									/>

									{isLoading && <Loading />}

									<div className="page-wrapper">
										<Search />
										<Switch>
											{routes.map(route => (
												<Route
													key={route.path}
													path={route.path}
													exact={route.exact}
													component={route.component}
												/>
											))}

											<Route component={NotFound} />
										</Switch>
									</div>
									<Footer />
								</React.Fragment>
							</IntlProvider>
						)}
					</LocaleContext.Consumer>
				</LocaleProvider>
			</div>
		);
	}
}

const mapToProps = ({ isLoading }) => ({
	isLoading,
});

export default withRouter(
	connect(
		mapToProps,
		actions,
	)(App),
);
