import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { PrimaryButton } from '../ui-toolkit/buttons.js';
import notFoundImage from '../../assets/images/404/404.svg';

import './not-found.scss';

const PageNotFound = ({ title }) => (
	<div className="not-found-wrapper">
		<div className="not-found-content">
			{title && <h1>{title}</h1>}
			<img src={notFoundImage} alt="Not found" />
			<div className="center-xs">
				<p className="title"><FormattedMessage id="not-found.title" /></p>
				<p className="message"><FormattedMessage id="not-found.message" /></p>
				<p>
					<a href="/">
						<PrimaryButton>
							<FormattedMessage id="not-implemented.button_label" />
						</PrimaryButton>
					</a>
				</p>
			</div>
		</div>
	</div>
);

export default injectIntl(PageNotFound);
