import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../../components/loading/loading.js';

const NewsPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "news-page" */'./news.page.js'),
	modules: ['./news.page.js'],
	webpack: () => [require.resolveWeak('./news.page.js')],
	loading: CustomLoadableLoading,
});

export default NewsPageAsync;
