const defaultSupportedLocale = {
	value: 'en',
	label: 'English',
};

const supportedLocales = [
	defaultSupportedLocale,
	{
		value: 'pt',
		label: 'Português',
	},
];

export { defaultSupportedLocale, supportedLocales };
