import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import SearchResultItem from './search-result-item';

class SearchResultCollection extends Component {
	render() {
		const { name, list, locale, keyword, closeSearch } = this.props;
		const localeTitle = `title${locale.value !== 'en' ? '_' + locale.value : ''}`;
		return (
			<div className="result-collection">
				<h5 className="collection-name">
					<FormattedMessage id={`navigation.${name}`} />
				</h5>
				{list.map(item => (
					<SearchResultItem
						key={item._id}
						type={name}
						date={item._source.date}
						slug={item._source.slug}
						title={item._source[localeTitle] || item._source.title}
						keyword={keyword}
						locale={locale}
						closeSearch={closeSearch}
					/>
				))}
			</div>
		);
	}
}

export default SearchResultCollection;
