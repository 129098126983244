import React, { Component } from 'react';
import { FormattedDate } from 'react-intl';
import { prefixPathWithLocale } from '../../locales/locale-paths';
import { Link } from 'react-router-dom';
import { escapeRegExp } from '../../shared/utils';

class SearchResultItem extends Component {
	render() {
		const {
			title,
			slug,
			date,
			type,
			keyword,
			locale,
			closeSearch
		} = this.props;

		let highlightedTitle = title;
		for (const word of keyword.split(' ')) {
			if (word.length >= 3) {
				const reg = new RegExp(escapeRegExp(word), 'gi');
				highlightedTitle =
					title &&
					highlightedTitle.replace(
						reg,
						str => `<b class='highlight'>${str}</b>`
					);
			}
		}

		return (
			<Link
				to={prefixPathWithLocale(`/${type}/${slug}`, locale)}
				className="item"
				onClick={closeSearch}
			>
				<div
					className="title"
					dangerouslySetInnerHTML={{ __html: highlightedTitle }}
				/>
				<span className="date">
					<FormattedDate
						value={new Date(date)}
						day="numeric"
						month="short"
						year="numeric"
					/>
				</span>
			</Link>
		);
	}
}
export default SearchResultItem;
