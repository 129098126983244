import { prefixPath } from '../locales/locale-paths.js';
import {
	supportedLocales,
	defaultSupportedLocale,
} from '../locales/supported-locales.js';

export default function localizeRoutes(routes) {
	return routes.map((route) => {
		// we default to localizing
		if (route.localize !== false) {
			return {
				...route,
				path: [
					route.path,
					prefixPath(
						route.path,
						`:locale(${supportedLocales
							.filter(
								supportedLanguage => supportedLanguage.value !== defaultSupportedLocale.value,
							)
							.map(supportedLanguage => supportedLanguage.value)
							.join('|')})`,
					),
				],
			};
		}

		return { ...route };
	});
}
