import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink as Link } from 'react-router-dom';
// eslint-disable-next-line import/extensions
import { connect } from 'redux-zero/react';
import cn from 'classnames';

import LocaleSwitcher from './locale-switcher.js';
import { withLocaleContext } from '../locales/locale-context.js';
import { actions } from '../shared/store.js';
import { prefixPathWithLocale } from '../locales/locale-paths.js';

import logo from '../assets/biocant_logo.svg';
import searchIcon from '../assets/icons/navbar/search.svg';

import './navbar.scss';

class Navbar extends React.Component {
	handleNavbarMenu = () => {
		const { toggleNav } = this.props;
		toggleNav();
	};

	handleNavigationClick = () => {
		const { closeNavbar } = this.props;
		closeNavbar();
	};

	toggleSearch = () => {
		document.dispatchEvent(new Event('toggleSearch'));
		this.handleNavigationClick();
	}

	render() {
		const {
			navbarOpen,
			localeContext: { locale },
			resetStore,
		} = this.props;

		return (
			<div className="site-navbar">
				<div className="container container-fluid">
					<div className="row">
						<div className="col-xs-6 col-md-3 start-xs middle-xs">
							<NavLogo />
						</div>
						<div className="col-xs-6 col-md-9 end-xs middle-xs">
							<NavList isOpen={navbarOpen}>
								<NavItem
									path={prefixPathWithLocale('/', locale)}
									exact
									label="navigation.home_page"
									handleNavigationClick={this.handleNavigationClick}
								/>
								<NavItem
									path={prefixPathWithLocale('/park', locale)}
									label="navigation.park"
									handleNavigationClick={this.handleNavigationClick}
								/>
								<NavItem
									path={prefixPathWithLocale('/services', locale)}
									label="navigation.services"
									handleNavigationClick={this.handleNavigationClick}
								/>
								<NavItem
									path={prefixPathWithLocale('/companies', locale)}
									label="navigation.companies"
									handleNavigationClick={this.handleNavigationClick}
								/>
								<NavItem
									path={prefixPathWithLocale('/news', locale)}
									label="navigation.news_events"
									handleNavigationClick={this.handleNavigationClick}
								/>
								<NavItem
									path={prefixPathWithLocale('/contacts', locale)}
									label="navigation.contacts"
									handleNavigationClick={this.handleNavigationClick}
								/>
								<li className="nav-item search-btn" onClick={() => this.toggleSearch()}>
									<img src={searchIcon} alt="search" />
									<FormattedMessage id="navigation.search" />
								</li>
							</NavList>
							<NavMenuButton
								isOpen={navbarOpen}
								handleClick={this.handleNavbarMenu}
							/>

							<LocaleSwitcher resetStore={resetStore} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const NavLogo = () => (
	<Link to="/">
		<img className="logo" src={logo} alt="Biocant" />
	</Link>
);

const NavList = ({ children, isOpen }) => (
	<nav className={`${cn({ open: isOpen })} nav-wrapper`}>
		<ul className="nav-list">{children}</ul>
	</nav>
);

const NavItem = ({
	path,
	label,
	externalLink,
	handleNavigationClick,
	exact,
}) => (
	<li className="nav-item">
		{!externalLink && (
			<Link
				to={path}
				exact={exact}
				onClick={handleNavigationClick}
				activeClassName="active"
			>
				<FormattedMessage id={label}>
					{txt => <Fragment>{txt}</Fragment>}
				</FormattedMessage>
			</Link>
		)}
		{externalLink && (
			<a
				href={path}
				target="_blank"
				rel="noopener noreferrer"
				onClick={handleNavigationClick}
			>
				<FormattedMessage id={label}>
					{txt => <Fragment>{txt}</Fragment>}
				</FormattedMessage>
			</a>
		)}
	</li>
);

const NavMenuButton = ({ isOpen, handleClick }) => (
	<button
		className={`${cn({ open: isOpen })} nav-menu-button`}
		onClick={handleClick}
	>
		<span />
		<span />
		<span />
		<span />
		<span />
		<span />
	</button>
);

const mapToProps = ({ mainData }) => ({
	mainData,
});
export default withLocaleContext(
	connect(
		mapToProps,
		actions,
	)(Navbar),
);

export { Navbar };
