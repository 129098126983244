import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const CareersPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "careers-page" */'./careers.page.js'),
	modules: ['./careers.page.js'],
	webpack: () => [require.resolveWeak('./careers.page.js')],
	loading: CustomLoadableLoading,
});

export default CareersPageAsync;
