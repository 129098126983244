import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const ServicesPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "services-page" */'./services.page.js'),
	modules: ['./services.page.js'],
	webpack: () => [require.resolveWeak('./services.page.js')],
	loading: CustomLoadableLoading,
});

export default ServicesPageAsync;
