import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const EcosystemPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "ecosystem-page" */'./ecosystem.page.js'),
	modules: ['./ecosystem.page.js'],
	webpack: () => [require.resolveWeak('./ecosystem.page.js')],
	loading: CustomLoadableLoading,
});

export default EcosystemPageAsync;
