import React from 'react';
import cn from 'classnames';

import { withLocaleContext } from '../locales/locale-context.js';

class LocaleSwitcher extends React.Component {
	handleLocaleChange = (selectedLocale) => {
		const {
			localeContext: {changeLocale},
			resetStore,
		} = this.props;

		resetStore();

		changeLocale(selectedLocale);
	};

	render() {
		const {
			localeContext: {locale, supportedLocales},
		} = this.props;

		function noOp() {
			return false;
		}

		return (
			<div className="i18n">
				{supportedLocales.map(supportedLocale => {
						const isSameLocale = locale.value === supportedLocale.value;

						return (
							<div key={supportedLocale.value} className="lang">
								<span
									className={`${cn({
										active: isSameLocale,
									})}`}
									onClick={() => isSameLocale ? noOp() : this.handleLocaleChange(supportedLocale)}
								>
									{supportedLocale.value.toUpperCase()}
								</span>
							</div>
						)
					}
				)}
			</div>
		);
	}
}

export default withLocaleContext(LocaleSwitcher);
