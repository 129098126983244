import { matchPath } from 'react-router';
import trim from 'lodash.trim';

import {
	supportedLocales,
	defaultSupportedLocale,
} from './supported-locales.js';

function prefixPath(path, prefix) {
	return `/${prefix}/${trim(path, '/')}`;
}

function removeLocaleFromPath(path) {
	if (path.split('/').length >= 2) {
		return `/${trim(
			path
				.split('/')
				.slice(2)
				.join('/'),
		)}`;
	}

	return path;
}

function replacePathLocale(path, prefix) {
	return prefixPath(removeLocaleFromPath(path), prefix);
}

function prefixPathWithLocale(path, locale) {
	if (locale.value === defaultSupportedLocale.value) return path;

	const match = matchPath(path, {
		path: supportedLocales
			.filter(
				supportedLocale => supportedLocale.value !== defaultSupportedLocale.value,
			)
			.map(({ value }) => `/:locale(${value})/*`),
	});

	if (match && locale.value === defaultSupportedLocale.value) {
		// remove the previous locale
		return removeLocaleFromPath(path);
	}

	if (match && match.params.locale === locale.value) {
		return path;
	}

	if (match && match.params.locale !== locale.value) {
		// replace the locale
		return replacePathLocale(path, locale.value);
	}

	return prefixPath(path, locale.value);
}

export { prefixPath, prefixPathWithLocale };
