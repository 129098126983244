import React, { Component } from 'react';
import SearchInput from './search-input';
import SearchResultList from './search-result-list';
import { withLocaleContext } from '../../locales/locale-context.js';
import { withRouter } from 'react-router';
import cn from 'classnames';

import './search.scss';
import SearchService from './search.service';

import { status } from './util';
import { escapeRegExp } from '../../shared/utils';

const initialState = {
	searchInput: '',
	results: [],
	keyword: '',
	status: status.INITIAL,
	open: false
};

class Search extends Component {
	constructor(props) {
		super(props);
		this.state = initialState;

		this.inputRef = React.createRef();

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.toggleSearch = this.toggleSearch.bind(this);
		this.clear = this.clear.bind(this);
		this.close = this.close.bind(this);
		this.clearInput = this.clearInput.bind(this);
	}

	componentDidMount() {
		document.addEventListener('toggleSearch', this.toggleSearch);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			this.close();
		}
	}

	handleChange(e) {
		this.setState({ searchInput: e.target.value });
	}

	handleSubmit(e) {
		e.preventDefault();
		this.inputRef.current.blur();
		if (this.state.searchInput.length >= 3) {
			this.search();
		}
	}

	componentWillUnmount() {
		document.removeEventListener('toggleSearch', this.toggleSearch);
	}

	toggleSearch() {
		if (this.state.open) {
			this.close();
		} else {
			this.setState({ open: true });
			this.inputRef.current.focus();
		}
	}

	search() {
		this.setState({ status: status.LOADING });

		SearchService.getSearchData(
			escapeRegExp(this.state.searchInput),
			this.props.localeContext.locale.value !== 'en'
				? this.props.localeContext.locale.value
				: undefined
		)
			.then(res => {
				const results = [];
				for (const collection in res) {
					if (res[collection].hits.hits.length) {
						results.push({
							name: collection.toLowerCase(),
							list: res[collection].hits.hits
						});
					}
				}
				if (!results.length) {
					this.setState({ status: status.NO_RESULTS, results, keyword: '' });
				} else {
					this.setState(prevState => ({
						results,
						keyword: prevState.searchInput,
						status: status.RESULTS
					}));
				}
			})
			.catch((error) => {
				if(error.response && error.response.status === 406) {
					this.setState({ status: status.NO_RESULTS, results: [], keyword: '' });
				} else {
				this.setState({ results: [], status: status.ERROR });}
			});
	}

	clear() {
		this.setState(prevState => ({ ...initialState, open: prevState.open }));
	}

	close() {
		this.setState(initialState);
	}

	clearInput() {
		this.clear();
		this.inputRef.current.focus();
	}

	render() {
		return (
			<React.Fragment>
				<div
					className={cn('search', {
						open: this.state.open,
						'no-results':
							this.state.status !== status.LOADING &&
							this.state.status === status.INITIAL
					})}
				>
					<div className="container center">
						<SearchInput
							inputRef={this.inputRef}
							value={this.state.searchInput}
							placeholder="What are you looking for?"
							onSubmit={this.handleSubmit}
							onChange={this.handleChange}
							onClose={
								this.state.status !== status.INITIAL &&
								this.state.status !== status.LOADING &&
								this.clearInput
							}
						/>
					</div>
					<SearchResultList
						results={this.state.results}
						keyword={this.state.keyword}
						locale={this.props.localeContext.locale}
						status={this.state.status}
						closeSearch={this.close}
					/>
				</div>
				<div
					className={cn('close-search', { open: this.state.open })}
					onClick={() => this.close()}
				/>
			</React.Fragment>
		);
	}
}

export default withRouter(withLocaleContext(Search));
