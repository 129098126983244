import React, { Component } from 'react';
import cn from 'classnames';
import SearchResultCollection from './search-result-collection';
import Loading from '../loading/loading';
import { status as utilStatus } from './util';
import { FormattedMessage } from 'react-intl';

class SearchResultList extends Component {
	render() {
		const { results, status, keyword, locale, closeSearch } = this.props;

		return (
			<div className="result-list-container">
				<div
					className={cn('result-list', {
						closed:
							status !== utilStatus.LOADING && status === utilStatus.INITIAL
					})}
				>
					{status === utilStatus.LOADING && (
						<div className="loading-container">
							<Loading />
						</div>
					)}
					{status === utilStatus.NO_RESULTS && (
						<div className="message-container center-xs">
							<h4>
								<FormattedMessage id="search.no-results-title" />
							</h4>
							<p>
								<FormattedMessage id="search.no-results-description" />
							</p>
						</div>
					)}
					{status === utilStatus.ERROR ? (
						<div className="message-container center-xs">
							<h4>
								<FormattedMessage id="search.error" />
							</h4>
						</div>
					) : (
						<div className="scrollable container">
							{results.map(collection => (
								<SearchResultCollection
									key={collection.name}
									name={collection.name}
									list={collection.list}
									locale={locale}
									keyword={keyword}
									closeSearch={closeSearch}
								/>
							))}
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default SearchResultList;
