import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import { BrowserRouter as Router } from 'react-router-dom';
// eslint-disable-next-line import/extensions
import { Provider } from 'redux-zero/react';

import GoogleAnalytics from './infrastructure/ga.js';
import { initializeStoreClient } from './shared/store.js';

import App from './app.js';

import './index.scss';

const ClientApp = () => (
	<Provider
		store={initializeStoreClient({
			// eslint-disable-next-line no-underscore-dangle
			...window.__APP_STATE__,
		})}
	>
		<Router>
			<GoogleAnalytics>
				<App />
			</GoogleAnalytics>
		</Router>
	</Provider>
);

// eslint-disable-next-line no-underscore-dangle
if (window.__SSR__) {
	window.onload = () => {
		Loadable.preloadReady().then(() => {
			ReactDOM.hydrate(<ClientApp />, document.getElementById('root'));
		});
	};
} else {
	ReactDOM.render(<ClientApp />, document.getElementById('root'));
}
